import React from "react";
import { useDDProTranslations } from "../../../dd-pro/useDDProTranslations";
import { Link } from "../../../../components/Link/Link";
import { DDProAllResourcesPage } from "../../../../pages";

const links = [
  {
    label: "Target protein ranges",
    to: "https://www.dietdoctor.com/wp-content/uploads/2021/02/Target-protein-ranges-TCR.pdf",
  },
  {
    label: "Monitoring ketones",
    to: "https://www.dietdoctor.com/wp-content/uploads/2020/02/Monitoring_Ketones-updated.pdf",
  },
  {
    label: "Fasting insulin and HOMA-IR ranges",
    to: "https://www.dietdoctor.com/wp-content/uploads/2020/02/Fasting-Insulin-and-HOMA-IR-ranges-updated.pdf",
  },
  {
    label: "Lab tests and follow-up schedule",
    to: "https://www.dietdoctor.com/wp-content/uploads/2020/02/Lab_test_and_follow_up-updated.pdf",
  },
  {
    label: "Type 2 diabetes medication reduction",
    to: "https://www.dietdoctor.com/wp-content/uploads/2020/02/Type-2-diabetes-medication-reduction-updated-1.pdf",
  },
];

export function PopularResourcesBlock() {
  const tt = useDDProTranslations();
  return (
    <div className="tour-4 rounded-md bg-lightest-grey flex flex-col p-6 mt-2">
      <div className="flex justify-between items-baseline">
        <div className="text-2xl tracking-tight">{tt.popularResources}</div>
        <Link to={DDProAllResourcesPage} className="text-base font-medium hover:opacity-75 transition-opacity">
          {tt.viewAll}
        </Link>
      </div>
      <ul className="list-none m-0 p-0 text-xl mt-4">
        {links.map((link, idx) => (
          <li key={idx} className="flex items-center my-2">
            <span className="flex">
              <ArticleIcon />
            </span>
            <a href={link.to} title={link.label} target="_blank" rel="noreferrer" className="truncate ml-2">
              {link.label}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

const ArticleIcon = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.75 5.91406V19.9141H5.75V5.91406H19.75ZM19.75 3.91406H5.75C4.65 3.91406 3.75 4.81406 3.75 5.91406V19.9141C3.75 21.0141 4.65 21.9141 5.75 21.9141H19.75C20.85 21.9141 21.75 21.0141 21.75 19.9141V5.91406C21.75 4.81406 20.85 3.91406 19.75 3.91406Z"
      fill="#6187CD"
    />
    <path
      d="M14.75 17.9141H7.75V15.9141H14.75V17.9141ZM17.75 13.9141H7.75V11.9141H17.75V13.9141ZM17.75 9.91406H7.75V7.91406H17.75V9.91406Z"
      fill="#6187CD"
    />
  </svg>
);
