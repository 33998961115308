import { FieldInputProps, FormikProps } from "formik";
import * as React from "react";
import { DDProSubscriptionTerm } from "../../../types/graphql-global-types";
import { useDDProTranslations } from "../useDDProTranslations";

export const SubscriptionTermSelect = ({
  field,
  title = "",
  ...props
}: { field: FieldInputProps<DDProSubscriptionTerm>; title: string } & FormikProps<{}>) => {
  const tt = useDDProTranslations();
  const hasTitle = title !== "";
  return (
    <div className="flex flex-col">
      {hasTitle && (
        <label htmlFor={field.name} className="font-medium py-2 text-base">
          {title}
        </label>
      )}
      <select
        tabIndex={0}
        className={`${
          hasTitle ? "bg-lighter-grey" : "bg-white"
        } mb-4 px-4 py-3 border-grey border rounded-lg w-full focus:bg-white focus:ring-2 focus:ring-green focus:shadow-md`}
        {...field}
        {...props}
      >
        <option value={DDProSubscriptionTerm.RECURRING}>{tt.monthlyRecurring}</option>
        <option value={DDProSubscriptionTerm.THREE_MONTHS_FIXED}>{tt.ThreeMonthsFixed}</option>
        <option value={DDProSubscriptionTerm.HALF_YEAR_FIXED}>{tt.SixMonthsFixed}</option>
        <option value={DDProSubscriptionTerm.NINE_MONTHS_FIXED}>{tt.NineMonthsFixed}</option>
        <option value={DDProSubscriptionTerm.YEAR_FIXED}>{tt.OneYearFixed}</option>
      </select>
    </div>
  );
};
