import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useCallback } from "react";
import { useUser } from "../../../hooks/useUser/useUser";
import { localePrefix } from "../../../pages";
import { sendEvent } from "../../../utils/gtm";
import { getLocale } from "../../../utils/locale";
import { useOnboardingTranslations } from "../translations";
import { OnboardingStep } from "../types";

export const OnboardingCTA = () => {
  const tt = useOnboardingTranslations();
  const { isPremium } = useUser();
  const locale = getLocale();

  const onboardingPath = `${localePrefix[locale]}/onboarding/${OnboardingStep.WELCOME}`;

  const handleOnClick = useCallback(() => {
    sendEvent({
      eventName: "onboarding",
      action: "onboarding_cta_clicked",
      payload: {
        isPremium,
      },
    });
  }, [isPremium]);

  const imageData = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "onboarding/onboarding-cta-image-desktop.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 486)
        }
      }

      mobile: file(relativePath: { eq: "onboarding/onboarding-cta-image-mobile.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 768)
        }
      }
    }
  `);

  return (
    <div>
      <div className="flex items-center mb-3 mt-6">
        <div className="flex-grow border-b border-light-grey"></div>

        <div className="px-3 uppercase text-grey tracking-widest text-xs">
          {tt.onBoardingBanner.premiumMembershipText}
        </div>
        <div className="flex-grow border-b border-light-grey"></div>
      </div>

      <div className="bg-green-500 rounded-xl flex flex-col md:flex-row overflow-hidden">
        <div className="p-5 md:p-10 w-full md:w-1/2 self-center text-center md:text-left">
          <h2 className="display-xxs sm:display-xs lg:display-s text-black mt-15 md:mt-0 mb-3">
            {tt.onBoardingBanner.title}
          </h2>
          <p className="text-black lg:body-l body-m m-0">{tt.onBoardingBanner.content}</p>

          <Link
            to={onboardingPath}
            onClick={handleOnClick}
            className="mt-5 inline-block rounded-full px-8 py-3 font-inter text-base font-medium bg-green text-white hover:bg-light-green"
          >
            {tt.onBoardingBanner.ctaButtonText}
          </Link>
        </div>
        <div className="w-full md:w-1/2 flex">
          <div className="hidden md:block pr-10">
            <GatsbyImage
              image={imageData.desktop?.childImageSharp?.gatsbyImageData}
              className="align-bottom"
              alt={tt.onBoardingBanner.title}
            />
          </div>
          <div className="block md:hidden self-end w-full">
            <GatsbyImage
              image={imageData.mobile?.childImageSharp?.gatsbyImageData}
              className="align-bottom"
              alt={tt.onBoardingBanner.title}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center my-4">
        <div className="flex-grow border-b border-light-grey"></div>
      </div>
    </div>
  );
};
