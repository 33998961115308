import { useCallback, useContext, useEffect } from "react";
import { ShepherdTourContext } from "react-shepherd";
import { ScreenSize, useScreenSize } from "../../../../../utils/screen-sizes";

type Props = {
  isAdminPays: boolean;
  setTourDone: () => void;
};

export function TourHandler({ isAdminPays, setTourDone }: Props) {
  const tour = useContext(ShepherdTourContext);
  const isDesktop = useScreenSize() > ScreenSize.LG;
  const handleTourDone = useCallback(() => setTourDone(), [setTourDone]);

  useEffect(() => {
    let cId: NodeJS.Timeout;
    if (isDesktop) {
      tour?.on("cancel", handleTourDone);
      tour?.on("complete", handleTourDone);

      if (!tour?.isActive()) {
        cId = setTimeout(() => {
          tour?.start();
        }, 1000);
      }
    }

    return () => {
      clearTimeout(cId);
      tour?.off("cancel", handleTourDone);
      tour?.off("complete", handleTourDone);
    };
  }, [handleTourDone, isAdminPays, isDesktop, tour]);

  return null;
}
