import React, { useMemo } from "react";
import { useDDProTranslations } from "../../../dd-pro/useDDProTranslations";
import { Link } from "../../../../components/Link/Link";
import {
  CMEPage,
  DDProAboutPage,
  DDProAddPage,
  DDProAllResourcesPage,
  DDProPage,
  DDProPDFPage,
  ExternalPage,
  RegularPage,
} from "../../../../pages";

const links = (tt: ReturnType<typeof useDDProTranslations>) => [
  { label: tt.addMember, to: DDProAddPage },
  { label: tt.members, to: DDProPage },
  { label: tt.qrCode, to: DDProPDFPage },
  { label: tt.courses, to: CMEPage },
  { label: tt.documentHub, to: DDProAllResourcesPage },
  { label: tt.aboutDdPro, to: DDProAboutPage },
];

export default function ProLinksSection() {
  const tt = useDDProTranslations();
  const linksLocalized = useMemo(() => links(tt), [tt]);
  return <div className="grid grid-cols-2 lg:grid-cols-6 my-2 lg:-mx-1">{linksLocalized.map(toLink)}</div>;
}

const toLink = ({ to, label }: { to: RegularPage | ExternalPage; label: string }, key: number) => (
  <Link
    className="label-l text-center py-3 rounded-lg hover:opacity-75 bg-green-100 my-1 mx-1 odd:ml-0 even:mr-0 lg:odd:ml-1 lg:even:mr-1"
    key={key}
    to={to}
  >
    {label}
  </Link>
);
