import React from "react";

export default function ProductsAndFeaturesLinks({
  title,
  firstText,
  firstLink,
  secondText,
  secondLink,
  thirdText,
  thirdLink,
  fourthText,
  fourthLink,
  fifthText,
  fifthLink,
  sixthText,
  sixthLink,
}: {
  title: string;
  firstText: string;
  firstLink: string;
  secondText?: string;
  secondLink?: string;
  thirdText?: string;
  thirdLink?: string;
  fourthText?: string;
  fourthLink?: string;
  fifthText?: string;
  fifthLink?: string;
  sixthText?: string;
  sixthLink?: string;
}) {
  return (
    <>
      <div className="pt-m">
        <h2 className="display-xs m-0">{title}</h2>
        <div className="grid grid-cols-2 lg:grid-cols-6 mt-2 lg:-mx-1">
          {firstText && firstLink && <ProductsAndFeaturesLink title={firstText} url={firstLink} />}
          {secondText && secondLink && <ProductsAndFeaturesLink title={secondText} url={secondLink} />}
          {thirdText && thirdLink && <ProductsAndFeaturesLink title={thirdText} url={thirdLink} />}
          {fourthText && fourthLink && <ProductsAndFeaturesLink title={fourthText} url={fourthLink} />}
          {fifthText && fifthLink && <ProductsAndFeaturesLink title={fifthText} url={fifthLink} />}
          {sixthText && sixthLink && <ProductsAndFeaturesLink title={sixthText} url={sixthLink} />}
        </div>
      </div>
      <hr className="border-t-0 border-b-8 mt-m border-sand -mx-3 lg:mx-auto" />
    </>
  );
}

const ProductsAndFeaturesLink = ({ title, url }: { title: string; url: string }) => (
  <a
    href={url}
    className="label-l text-center py-3 rounded-lg hover:opacity-75 bg-gray-blue-500 text-black my-1 mx-1 odd:ml-0 even:mr-0 lg:odd:ml-1 lg:even:mr-1"
  >
    {title}
  </a>
);
