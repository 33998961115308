import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

export default function GetHealthySection({
  title,
  firstLabel,
  firstLink,
  secondLabel,
  secondLink,
  thirdLabel,
  thirdLink,
  fourthLabel,
  fourthLink,
  fifthLabel,
  fifthLink,
}: {
  title: string;
  firstLabel: string;
  firstLink: string;
  secondLabel: string;
  secondLink: string;
  thirdLabel: string;
  thirdLink: string;
  fourthLabel: string;
  fourthLink: string;
  fifthLabel: string;
  fifthLink: string;
}) {
  const andreasImage = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logged-in-home/andreas-broccoli.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 512)
        }
      }
    }
  `);

  return (
    <div className="mt-l">
      <h2 className="m-0 display-xs">{title}</h2>

      <div className="flex flex-col md:flex-row items-center md:justify-start mt-6">
        <GatsbyImage
          image={andreasImage.file?.childImageSharp?.gatsbyImageData}
          alt="andreas"
          className="rounded-md h-56 sm:h-72 w-full"
          objectPosition="center 0%"
          style={{ maxWidth: "544px" }}
        />

        <ul className="w-full md:w-full font-medium label-l list-none p-0 m-0 mt-m md:mt-0">
          <li className="md:ml-s border-b border-grey border-opacity-50">
            <a href={firstLink} className="inline-block py-3 md:pl-xs text-black w-full hover:opacity-75">
              {firstLabel}
            </a>
          </li>
          <li className="md:ml-s border-b border-grey border-opacity-50">
            <a href={secondLink} className="inline-block py-3 md:pl-xs text-black w-full hover:opacity-75">
              {secondLabel}
            </a>
          </li>
          <li className="md:ml-s border-b border-grey border-opacity-50">
            <a href={thirdLink} className="inline-block py-3 md:pl-xs text-black w-full hover:opacity-75">
              {thirdLabel}
            </a>
          </li>
          <li className="md:ml-s border-b border-grey border-opacity-50">
            <a href={fourthLink} className="inline-block py-3 md:pl-xs text-black w-full hover:opacity-75">
              {fourthLabel}
            </a>
          </li>
          {fifthLink && fifthLabel && (
            <li className="md:ml-s border-b border-grey border-opacity-50">
              <a href={fifthLink} className="inline-block py-3 md:pl-xs text-black w-full hover:opacity-75">
                {fifthLabel}
              </a>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}
