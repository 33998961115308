import { graphql } from "gatsby";
import { useStaticQuery } from "gatsby";
import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

export default function LearnLinks({
  title,
  buttonLabel,
  buttonLink,
  firstLabel,
  firstLink,
  secondLabel,
  secondLink,
  thirdLabel,
  thirdLink,
  fourthLabel,
  fourthLink,
}: {
  title: string;
  buttonLabel?: string;
  buttonLink?: string;
  firstLabel: string;
  firstLink: string;
  secondLabel: string;
  secondLink: string;
  thirdLabel: string;
  thirdLink: string;
  fourthLabel: string;
  fourthLink: string;
}) {
  const images = useStaticQuery(graphql`
    query {
      measure: file(relativePath: { eq: "logged-in-home/measure.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 384)
        }
      }
      avocado: file(relativePath: { eq: "logged-in-home/avocado.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 384)
        }
      }
      bowl: file(relativePath: { eq: "logged-in-home/bowl.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 384)
        }
      }
      shrimp: file(relativePath: { eq: "logged-in-home/shrimp.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 384)
        }
      }
    }
  `);

  return (
    <div className="mt-l">
      <div className="flex flex-row justify-between items-center">
        <h2 className="display-xs m-0">{title}</h2>
        {buttonLink && buttonLabel && (
          <a href={buttonLink} className="label-m hover:underline" style={{ color: "#6187CD" }}>
            {buttonLabel}
          </a>
        )}
      </div>

      <div className="flex flex-row flex-wrap mt-m space-y-m lg:space-y-0">
        <div className="flex flex-row space-x-2 justify-between lg:pr-1 w-full lg:w-1/2">
          <a href={firstLink} className="text-center my-0 w-full text-black hover:opacity-75">
            <GatsbyImage
              image={images.measure?.childImageSharp?.gatsbyImageData}
              alt="measure"
              className="rounded-lg bg-[#E9F2ED] overflowfix"
            />
            <p className="m-0 mt-xs label-l">{firstLabel}</p>
          </a>
          <a href={secondLink} className="text-center my-0 w-full text-black hover:opacity-75">
            <GatsbyImage
              image={images.avocado?.childImageSharp?.gatsbyImageData}
              alt="avocado"
              className="rounded-lg bg-[#E9F2ED] overflowfix"
            />
            <p className="m-0 mt-xs label-l">{secondLabel}</p>
          </a>
        </div>
        <div className="flex flex-row space-x-2 justify-between lg:pl-1 w-full lg:w-1/2">
          <a href={thirdLink} className="text-center my-0 w-full text-black hover:opacity-75">
            <GatsbyImage
              image={images.bowl?.childImageSharp?.gatsbyImageData}
              alt="bowl"
              className="rounded-lg bg-[#E9F2ED] overflowfix"
            />
            <p className="m-0 mt-xs label-l">{thirdLabel}</p>
          </a>
          <a href={fourthLink} className="text-center my-0 w-full text-black hover:opacity-75">
            <GatsbyImage
              image={images.shrimp?.childImageSharp?.gatsbyImageData}
              alt="shrimp"
              className="rounded-lg bg-[#E9F2ED] overflowfix"
            />

            <p className="m-0 mt-xs label-l">{fourthLabel}</p>
          </a>
        </div>
      </div>
    </div>
  );
}
