import React, { useContext, useEffect, useState } from "react";
import Loading from "../../../components/Loading/Loading";
import { LoginContext } from "../../../components/LoginProvider/LoginProvider";
import { useQuery } from "@apollo/client";
import GetHomePageActiveMealPlanQuery from "../api/GetHomePageActiveMealPlan.graphql";
import { Link } from "../../../components/Link/Link";
import { MealPlanCarousel } from "../../../components/MealPlannerPage/MealPlanCarousel/MealPlanCarousel";
import { MealPlanForList } from "../../../components/MealPlannerPage/types";
import { MealPlanPage } from "../../mealplans/pages";

const HomePageMealPlanCarousel = ({
  setIsActiveCarouselLoaded,
  className,
  title,
  buttonLabel,
}: {
  setIsActiveCarouselLoaded: React.Dispatch<React.SetStateAction<boolean | null>>;
  className?: string;
  title: string;
  buttonLabel: string;
  minutes: string;
}) => {
  const { loggedIn, premium: isPremium } = useContext(LoginContext);

  const [data, setData] = useState<any>(null);

  const {
    loading: activeMealPlanLoading,
    data: activeMealPlanData,
    error: activeMealPlanError,
    refetch: getActiveMealPlan,
  } = useQuery(GetHomePageActiveMealPlanQuery, {
    skip: !loggedIn || !isPremium,
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  useEffect(() => {
    if (loggedIn) {
      if (isPremium) {
        getActiveMealPlan();
      } else {
        setIsActiveCarouselLoaded(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  useEffect(() => {
    if (!activeMealPlanLoading) {
      if (activeMealPlanData && activeMealPlanData?.activeMealplan?.schedule) {
        setData(activeMealPlanData);
        setIsActiveCarouselLoaded(true);
      } else if ((activeMealPlanData && activeMealPlanData?.activeMealplan === null) || activeMealPlanError) {
        setIsActiveCarouselLoaded(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMealPlanLoading]);

  useEffect(() => {
    if (activeMealPlanData && activeMealPlanData?.activeMealplan?.schedule) {
      setIsActiveCarouselLoaded(true);
    }
  }, []);

  const successfullyLoaded = !activeMealPlanError && data?.activeMealplan;

  return (
    <section className={className ? className : ""}>
      <div className="flex flex-row justify-between items-center mb-m">
        <h2 className="m-0 display-xs">{title}</h2>
        {successfullyLoaded && (
          <Link
            to={MealPlanPage}
            className="label-m py-1 lg:py-2 hover:underline text-center mr-3 xl:mr-0 my-0"
            params={{ slug: (data.activeMealplan as MealPlanForList).slug }}
            query={{}}
            style={{ color: "#6187CD" }}
          >
            {buttonLabel}
          </Link>
        )}
      </div>
      {successfullyLoaded ? (
        <MealPlanCarousel
          mealPlan={data.activeMealplan as MealPlanForList}
          isActive={true}
          isWholeWeek={true}
        />
      ) : !loggedIn || activeMealPlanLoading ? (
        <Loading />
      ) : null}
    </section>
  );
};

export default React.memo(HomePageMealPlanCarousel);
