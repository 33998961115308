import * as React from "react";
import { IconProps } from "./types";

export const StarIcon = ({ className, color = "#F8D960", ...rest }: IconProps) => (
  <svg
    className={className}
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M5.45378 0.910797L6.96044 4.29085L10.6406 4.67927L7.89161 7.15668L8.65944 10.7768L5.45378 8.92786L2.24813 10.7768L3.01596 7.15668L0.266927 4.67927L3.94713 4.29085L5.45378 0.910797Z"
      fill={color}
    />
  </svg>
);
