import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useContext } from "react";
import { Link } from "../../../components/Link/Link";
import { LoginContext } from "../../../components/LoginProvider/LoginProvider";
import { useOnboardingPreferences } from "../../onboarding/hooks/useOnboardingPreferences";
import { OnboardingGenderPage } from "../../onboarding/pages";
import { MyMealPlansPage } from "../../../pages";
import Divider from "./Divider";
export default function MealPlanCTA({
  title,
  body,
  cta,
  ctaLink,
  learnMore,
  learnMoreLink,
}: {
  title: string;
  body: string;
  cta: string;
  ctaLink: string;
  learnMore: string;
  learnMoreLink: string;
}) {
  const { premium } = useContext(LoginContext);

  const imageData = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "logged-in-home/meal-plans-cta-desktop.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 960)
        }
      }
      mobile: file(relativePath: { eq: "logged-in-home/meal-plans-cta-mobile.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 768)
        }
      }
    }
  `);

  const { hasPreferences: hasOnboardingPreferences } = useOnboardingPreferences();

  return (
    <>
      <div className="relative flex flex-col justify-center mt-l">
        <div className="z-10 text-center lg:text-left h-96 lg:h-64 mx-s my-l lg:mx-xl lg:w-6/12 lg:pr-l flex flex-col justify-center">
          <h2 className="display-s text-blue font-medium m-0">{title}</h2>
          <p className="m-0 body-l mt-m">{body}</p>
          <div className="flex flex-col lg:flex-row items-center justify-center lg:justify-start space-y-4 lg:space-y-0 lg:space-x-4 mt-m">
            {!hasOnboardingPreferences && !premium && (
              <a
                href={ctaLink}
                className="bg-green p-3 px-4 w-52 rounded-full text-white font-medium body-m text-center hover:opacity-75 m-0"
              >
                {cta}
              </a>
            )}

            {!hasOnboardingPreferences && premium && (
              <Link
                to={OnboardingGenderPage}
                className="bg-green p-3 px-4 w-52 rounded-full text-white font-medium body-m text-center hover:opacity-75 m-0"
              >
                {cta}
              </Link>
            )}

            {hasOnboardingPreferences && (
              <Link
                to={MyMealPlansPage}
                className="bg-green p-3 px-4 w-52 rounded-full text-white font-medium body-m text-center hover:opacity-75 m-0"
              >
                {cta}
              </Link>
            )}

            {!hasOnboardingPreferences && !premium && (
              <a
                href={learnMoreLink}
                className="bg-white border border-green p-3 px-4 w-52 text-center rounded-full font-white text-green font-medium body-m hover:opacity-75 m-0"
              >
                {learnMore}
              </a>
            )}
          </div>
        </div>

        {imageData.mobile && (
          <div className="block lg:hidden">
            <GatsbyImage
              image={imageData.mobile?.childImageSharp?.gatsbyImageData}
              className="w-full h-full inset-0 rounded-2xl"
              objectFit="cover"
              objectPosition="center 57.5%"
              style={{ position: "absolute" }}
              alt=""
            />
          </div>
        )}

        {imageData.desktop && (
          <div className="hidden lg:block">
            <GatsbyImage
              image={imageData.desktop?.childImageSharp?.gatsbyImageData}
              className="w-full h-full inset-0 rounded-2xl"
              objectFit="cover"
              objectPosition="center 31.25%"
              style={{ position: "absolute" }}
              alt=""
            />
          </div>
        )}
      </div>

      <Divider />
    </>
  );
}
