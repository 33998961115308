import { useQuery } from "@apollo/client";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { i18nContext } from "../../../components/I18nProvider/I18nProvider";
import { Link } from "../../../components/Link/Link";
import { LoginContext } from "../../../components/LoginProvider/LoginProvider";
import { pageContext } from "../../../components/PageProvider/PageProvider";
import { useOnboardingPreferences } from "../../onboarding/hooks/useOnboardingPreferences";
import { OnboardingGenderPage } from "../../onboarding/pages";
import { BaseRecipe } from "../../../types/BaseRecipe";
import { Locale } from "../../../types/Locale";
import getMealTime from "../../../utils/getMealTime";
import GetRecipeSuggestions from "../api/GetRecipeSuggestions.graphql";
import SingleRecipePreview from "./SingleRecipePreview";
import SkeletonSingleRecipeSuggestions from "./SkeletonSingleRecipeSuggestions";

const MealtimeRecipeSuggestions = ({
  ideas,
  refreshButtonLabel,
  personalizeButtonLabel,
}: {
  ideas: string;
  refreshButtonLabel?: string;
  personalizeButtonLabel?: string;
}) => {
  const { translations: tt } = useContext(i18nContext);
  const { loggedIn, premium } = useContext(LoginContext);
  const { locale } = useContext(pageContext);

  const currentMeal = useMemo(() => getMealTime(), []);

  const [suggestionsSkip, setSuggestionsSkip] = useState(true);
  const [finalSuggestionsData, setFinalSuggestionsData] = useState<any>(null);

  const {
    loading: suggestionsLoading,
    data: suggestionsData,
    refetch: getRecipes,
  } = useQuery(GetRecipeSuggestions, {
    skip: suggestionsSkip,
    variables: { recipeCount: 4, meal: currentMeal },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-and-network",
  });

  const { hasPreferences: hasOnboardingPreferences } = useOnboardingPreferences();

  const handleGetMoreRecipes = useCallback(async () => {
    setSuggestionsSkip(false);
    getRecipes();
  }, [getRecipes]);

  useEffect(() => {
    if (loggedIn) {
      setSuggestionsSkip(false);
      getRecipes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  useEffect(() => {
    if (!suggestionsLoading && suggestionsData) {
      setFinalSuggestionsData(suggestionsData);
      setSuggestionsSkip(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestionsLoading]);

  const meal = tt.meals.mealLabel(currentMeal, true);

  return (
    <>
      {/* <h3 className='text-blue text-sm lg:text-base font-medium opacity-50 m-0 -mt-2 mb-2'>
        {tt.meals.mealLabel(currentMeal, true).toUpperCase()} {ideas.toUpperCase()}
      </h3> */}

      <div className="flex flex-row justify-between items-center">
        <h2 className="display-xs m-0">
          {locale == Locale.ES ? (
            <>
              {ideas} de {meal.toLowerCase()}
            </>
          ) : (
            <>
              {meal.includes("Middag") ? meal + "s" : meal}
              {locale == Locale.SV ? ideas.toLowerCase() : ` ${ideas}`}
            </>
          )}
        </h2>

        {hasOnboardingPreferences && premium ? (
          <button
            onClick={handleGetMoreRecipes}
            className="label-m hover:underline my-0"
            style={{ color: "#6187CD" }}
          >
            {refreshButtonLabel || "Refresh"}
          </button>
        ) : (
          <Link to={OnboardingGenderPage} className="label-m hover:underline my-0" style={{ color: "#6187CD" }}>
            {personalizeButtonLabel || "Personalize"}
          </Link>
        )}
      </div>

      <ul className="list-none flex flex-row flex-wrap p-0 my-0 px-0">
        {suggestionsLoading || !finalSuggestionsData
          ? Array(4)
              .fill(null)
              .map((item, index) => <SkeletonSingleRecipeSuggestions key={index} />)
          : finalSuggestionsData?.recipeSuggestions &&
            finalSuggestionsData.recipeSuggestions.map((recipe: BaseRecipe) => (
              <SingleRecipePreview recipe={recipe} key={recipe.slug} />
            ))}
      </ul>
    </>
  );
};

export default React.memo(MealtimeRecipeSuggestions);
