import * as React from "react";
import { useDDProTranslations } from "../../../dd-pro/useDDProTranslations";
import { Link } from "../../../../components/Link/Link";
import { createExternalPage } from "../../../../page-utils";
import { localePrefix } from "../../../../pages";

type Props = { numTotal: number; numActive: number };

export function MembersCountBlock({ numTotal, numActive }: Props) {
  const tt = useDDProTranslations();

  return (
    <div className="h-full rounded-md transition-all bg-green-100 flex p-6 w-full">
      <Link to={DDProPage} className="text-dark-grey flex flex-col h-full w-full">
        <div className="flex justify-between items-baseline">
          <div className="text-2xl tracking-tight">{tt.myMembers}</div>
          <span className="text-base font-medium hover:opacity-75 transition-opacity text-light-blue">
            {tt.viewAll}
          </span>
        </div>
        <div className="flex flex-col h-full justify-center py-4">
          <div className="flex">
            <div className="w-1/2 flex flex-col items-center border-r border-light-grey">
              <div className="mb-1 text-6xl font-medium">{numTotal}</div>
              <div className="text-2xl">{tt.total}</div>
            </div>
            <div className="w-1/2 flex flex-col items-center">
              <div className="mb-1 text-6xl font-medium text-light-blue">{numActive}</div>
              <div className="text-2xl text-light-blue">{tt.actives}</div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

const DDProPage = createExternalPage({
  name: "ddpro",
  path: (locale) => `${localePrefix[locale]}/pro`,
});
