import { IUser } from "@dietdoctor/elements";
import { useMachine } from "@xstate/react";
import { Field, Form, Formik } from "formik";
import React, { useEffect } from "react";
import { assign, createMachine } from "xstate";
import * as yup from "yup";
import { ActionButton } from "../../../dd-pro/form/ActionButton";
import { EmailInput } from "../../../dd-pro/form/EmailInput";
import { SubscriptionTermSelect } from "../../../dd-pro/form/SubscriptionTermSelect";
import { useDDProTranslations } from "../../../dd-pro/useDDProTranslations";
import { Link } from "../../../../components/Link/Link";
import AddUser from "../../../../graphql/DDPro/AddUser.graphql";
import { ErrorEmojicon } from "../../../../icons/ErrorEmojicon";
import { createExternalPage } from "../../../../page-utils";
import { localePrefix } from "../../../../pages";
import { DDProSubscriptionTerm } from "../../../../types/graphql-global-types";
import { client } from "../../../../utils/apollo-client";

export function QuickAddBlock({
  onQuickMemberAdded,
  isAdminPays,
  user,
}: {
  isAdminPays: boolean;
  onQuickMemberAdded: () => void;
  user?: IUser;
}) {
  const tt = useDDProTranslations();
  const [state, send] = useMachine(machine, {
    actions: { onQuickMemberAdded },
  });

  useEffect(() => {
    send({ type: "UPDATE_GROUP_ID", groupId: user?.pro?.group.id ?? "" });
  }, [user?.pro, send]);

  const isSuccess = state.matches("success");
  const isError = state.matches("error");
  const isAdding = state.matches("adding");

  if (isSuccess) {
    return (
      <div className="bg-green flex flex-col items-center justify-center p-6 rounded-md w-full">
        <Check />
        <p className="text-white text-2xl m-0">{tt.xHasBeenAdded(tt.newMember)}</p>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="bg-green-100 flex flex-col items-center justify-center p-6 rounded-md w-full">
        <div style={{ color: "#B7C2D7" }}>
          <ErrorEmojicon />
        </div>
        <p className="text-2xl m-0 text-center">{tt.unknownErrorPleaseContactYourDdProAdministrator}</p>
      </div>
    );
  }

  return (
    <div className="rounded-md bg-green-100 flex flex-col p-6 w-full">
      <div className="flex justify-between items-baseline">
        <div className="text-2xl tracking-tight">Quick add</div>
        <Link to={AddProMemberPage} className="text-base font-medium hover:opacity-75 transition-opacity">
          {tt.addMember}
        </Link>
      </div>
      <Formik
        initialValues={{ email: "", subscriptionTerm: DDProSubscriptionTerm.RECURRING }}
        validationSchema={yup.object({
          email: yup.string().email(tt.enterAValidEmail).required(tt.emailIsRequired),
        })}
        onSubmit={async (values, formikHelpers) => {
          const member = {
            email: values.email,
            subscriptionTerm: values.subscriptionTerm,
          };
          send({ type: "SUBMIT", member, reset: formikHelpers.resetForm });
        }}
      >
        {({ dirty, errors }) => (
          <Form className="flex flex-col h-full justify-center mt-4">
            <Field type="email" name="email" component={EmailInput} title="" />
            {isAdminPays && (
              <Field as="select" name="subscriptionTerm" component={SubscriptionTermSelect} title="" />
            )}
            <div className={`flex  ${isAdminPays ? "justify-end" : "justify-center"}`}>
              <ActionButton
                type="submit"
                label={tt.submit}
                busy={isAdding}
                disabled={typeof errors.email === "string" || !dirty}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

type Context = {
  groupId: string;
};

type MemberPayload = {
  email: string;
  subscriptionTerm: DDProSubscriptionTerm;
};

type UpdateGroupIdEvent = { type: "UPDATE_GROUP_ID"; groupId: string };
type SubmitEvent = { type: "SUBMIT"; member: MemberPayload; reset: () => any };
type MachineEvent = UpdateGroupIdEvent | SubmitEvent;

const resetForm = (_: Context, event: MachineEvent) => (event as SubmitEvent).reset();

const add = (ctx: Context, event: MachineEvent) =>
  client.mutate({
    mutation: AddUser,
    variables: {
      input: {
        groupId: ctx.groupId,
        firstName: "",
        lastName: "",
        email: (event as SubmitEvent).member.email,
        subscriptionTerm: (event as SubmitEvent).member.subscriptionTerm,
      },
    },
  });

const machine = createMachine<Context, MachineEvent>(
  {
    initial: "idle",
    on: {
      UPDATE_GROUP_ID: {
        actions: ["updateGroupId"],
      },
    },
    states: {
      idle: {
        on: {
          SUBMIT: "adding",
        },
      },
      adding: {
        entry: ["resetForm"],
        invoke: {
          src: "add",
          onDone: "success",
          onError: "error",
        },
      },
      success: {
        entry: ["onQuickMemberAdded"],
        after: {
          5000: "idle",
        },
      },
      error: {
        after: {
          5000: "idle",
        },
      },
    },
    predictableActionArguments: true,
  },
  {
    actions: {
      updateGroupId: assign((_, e: MachineEvent) => ({
        groupId: (e as UpdateGroupIdEvent).groupId,
      })),
      resetForm,
    },
    services: {
      add,
    },
  }
);

const AddProMemberPage = createExternalPage({
  name: "add-pro-member",
  path: (locale) => `${localePrefix[locale]}/pro/add`,
});

const Check = () => (
  <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.0349 43.962L12.8349 32.762L9.10156 36.4953L24.0349 51.4286L56.0349 19.4286L52.3016 15.6953L24.0349 43.962Z"
      fill="white"
    />
  </svg>
);
