import { ShepherdOptionsWithType, Step } from "react-shepherd";

export type TourStep = {
  element: string;
  position: Step.PopperPlacement;
  title: string;
  text: string;
  memberPays?: boolean;
};

const buildButtons = (idx: number, isLast: boolean) => {
  const buttons = [
    {
      classes: "shepherd-button-primary",
      text: isLast ? "Done" : "Next",
      type: "next",
    },
  ];

  if (idx > 0) {
    return [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      ...buttons,
    ];
  }

  return buttons;
};

export const toShepherdOptionsWithType = (
  data: TourStep,
  idx: number,
  arr: TourStep[]
): ShepherdOptionsWithType => {
  const attachTo =
    data.element === "" ? {} : { attachTo: { element: data.element, on: "auto" as Step.PopperPlacement } };
  return {
    arrow: data.element !== "",
    ...attachTo,
    modalOverlayOpeningPadding: 12,
    modalOverlayOpeningRadius: 12,
    buttons: buildButtons(idx, idx === arr.length - 1),
    classes: "bg-turquoise-600 rounded-lg w-96 text-base shadow-lg",
    highlightClass: "",
    scrollTo: { behavior: "smooth", block: "center" },
    cancelIcon: {
      enabled: true,
    },
    title: data.title,
    text: data.text,
  };
};
