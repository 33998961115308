import React from "react";
import { useDDProTranslations } from "../../../dd-pro/useDDProTranslations";
import { StarIcon } from "../../../../icons/StarIcon";

export function BenefitsBlock({ isAdminPays }: { isAdminPays: boolean }) {
  const tt = useDDProTranslations();
  return (
    <div className="tour-3 mt-2 rounded-md bg-lightest-grey flex flex-col p-6 col-span-2">
      <div className="flex">
        <div className="text-2xl tracking-tight">{tt.memberBenefits}</div>
      </div>
      <div className="flex flex-col">
        <div className="grid grid-cols-4">
          <ul className="list-none m-0 p-0 col-span-4 md:col-span-3 md:col-start-2 text-xl mt-4">
            <li className="my-2 flex items-baseline">
              <Star />
              <span className="ml-2">{tt.nutritionallyReviewedRecipes}</span>
            </li>
            <li className="my-2 flex items-baseline">
              <Star />
              <span className="ml-2">{tt.supportiveModeratedCommunity}</span>
            </li>
            <li className="my-2 flex items-baseline">
              <Star />
              <span className="ml-2">{tt.personalizedMealPlansShoppingLists}</span>
            </li>
            <li className="my-2 flex items-baseline">
              <Star />
              <span className="ml-2">{tt.weightLossProgramsIncluded}</span>
            </li>
            <li className="my-2 flex items-baseline">
              <Star />
              <span className="ml-2">{tt.progressTrackingInTheApp}</span>
            </li>
            {!isAdminPays && (
              <li className="my-2 flex items-baseline">
                <Star />
                <span className="ml-2">{tt.oneMonthFreeTrial}</span>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}

const Star = () => <StarIcon width={22} height={22} color="#06B05B" />;
