/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "../../components/Link/Link";
import Loading from "../../components/Loading/Loading";
import { LoginContext } from "../../components/LoginProvider/LoginProvider";
import { pageContext } from "../../components/PageProvider/PageProvider";
import { SEOReactHelmet } from "../../components/SEO/SEOReactHelmet";
import { useUser } from "../../hooks/useUser/useUser";
import { LoginPage, MyMealPlansPage } from "../../pages";
import TrackingSection from "../tracking/components/TrackingSection";
import { Locale } from "../../types/Locale";
import { isBrowser } from "../../utils/ssr";
import ChallengesSection from "./components/ChallengesSection";
import Divider from "./components/Divider";
import FeaturedArticles from "./components/FeaturedArticles";
import GetHealthySection from "./components/GetHealthySection";
import HomePageMealPlanCarousel from "./components/HomePageMealPlanCarousel";
import LearnLinks from "./components/LearnLinks";
import MealPlanCTA from "./components/MealPlanCTA";
import MealtimeRecipeSuggestions from "./components/MealtimeRecipeSuggestions";
import MyFavoriteRecipes from "./components/MyFavoriteRecipes";
import ProSection from "./components/pro/ProSection";
import SixLinksSection from "./components/SixLinksSection";
import TimeOfDayGreeting from "./components/TimeOfDayGreeting";
import ProductsAndFeaturesLinks from "./components/ProductsAndFeaturesLinks";
import { t } from "@lingui/macro";
import { useOnboardingPreferences } from "../onboarding/hooks/useOnboardingPreferences";
import { OnboardingCTA } from "../onboarding/components/OnboardingCTA";
import { graphql } from "gatsby";

const LoggedInHomePage = ({ data }: { data: LoggedInHomeData }) => {
  const cmsData = data.directus;
  const tt = useTranslations();
  const { locale } = useContext(pageContext);

  if (!isBrowser()) {
    return null;
  }

  const { user } = useUser();
  const { loading: authLoading, loggedIn, user: userData } = useContext(LoginContext);

  const navigate = useNavigate();

  const { hasPreferences: hasOnboardingPreferences } = useOnboardingPreferences();

  const [isActiveCarouselLoaded, setIsActiveCarouselLoaded] = useState<boolean | null>(null);
  const [isFavoriteRecipesLoaded, setIsFavoriteRecipesLoaded] = useState<boolean | null>(null);

  // Redirect logged-out user to login page
  useEffect(() => {
    if (!authLoading && !userData) {
      navigate({ to: LoginPage, query: { redirectTo: window.location.href } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authLoading]);

  // Scroll to top on load
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatchEvent(new Event("loadedPage"));
  }, [isActiveCarouselLoaded, loggedIn]);

  return (
    <>
      <SEOReactHelmet title={tt.seoTitle} description={tt.seoDescription} lang={locale as string} />

      {(isActiveCarouselLoaded === null || !loggedIn) && <Loading />}

      <div
        className={`px-3 pb-24 w-full mx-auto ${isActiveCarouselLoaded === null || !loggedIn ? "hidden" : ""}`}
        style={{ maxWidth: "1200px" }}
      >
        {!user?.pro && userData?.firstName && (
          <div
            className="flex flex-row justify-between items-center content-center"
            style={{ margin: "20px 0" }}
          >
            <TimeOfDayGreeting
              name={userData.firstName}
              morningGreeting={tt.morningGreeting}
              afternoonGreeting={tt.afternoonGreeting}
              eveningGreeting={tt.eveningGreeting}
            />

            <Link
              to={MyMealPlansPage}
              className="bg-lighter-blue rounded-full body-s font-medium py-1 lg:py-2 px-6 text-black hover:opacity-75 w-36 sm:w-44 text-center my-0"
            >
              {tt.myMealPlansButtonText}
            </Link>
          </div>
        )}

        {user?.pro && <ProSection pro={user?.pro} />}

        {!hasOnboardingPreferences && <OnboardingCTA />}

        {/* Active meal plan */}

        <section
          className="-mx-3 xl:mx-0 xl:breakout pt-m pb-xl overflow-x-hidden"
          style={{ backgroundColor: "rgba(243, 242, 239, 0.5)" }}
        >
          <div className={`mx-auto px-3 -mr-3 xl:mr-auto`} style={{ maxWidth: "1200px" }}>
            <HomePageMealPlanCarousel
              setIsActiveCarouselLoaded={setIsActiveCarouselLoaded}
              title={tt.nextMealsTitle}
              buttonLabel={tt.activeMealPlanButtonText}
              minutes={tt.minutesSufix}
              className={`${isActiveCarouselLoaded === false ? "hidden" : ""}`}
            />
            {isActiveCarouselLoaded === false && (
              <MealtimeRecipeSuggestions
                ideas={tt.ideasText}
                refreshButtonLabel={tt.refreshText}
                personalizeButtonLabel={tt.personalizeText}
              />
            )}
          </div>
        </section>

        <ProductsAndFeaturesLinks
          title={tt.quickLinksSectionTitle}
          firstText={tt.quickLinksSectionLinkOneTitle}
          firstLink={tt.quickLinksSectionLinkOneUrl}
          secondText={tt.quickLinksSectionLinkTwoTitle}
          secondLink={tt.quickLinksSectionLinkTwoUrl}
          thirdText={tt.quickLinksSectionLinkThreeTitle}
          thirdLink={tt.quickLinksSectionLinkThreeUrl}
          fourthText={tt.quickLinksSectionLinkFourTitle}
          fourthLink={tt.quickLinksSectionLinkFourUrl}
          fifthText={tt.quickLinksSectionLinkFiveTitle}
          fifthLink={tt.quickLinksSectionLinkFiveUrl}
          sixthText={tt.quickLinksSectionLinkSixTitle}
          sixthLink={tt.quickLinksSectionLinkSixUrl}
        />

        {locale === Locale.EN || locale === Locale.SV || (window as any)?.showLatestNews ? (
          <FeaturedArticles
            title={tt.latestSectionTitle}
            articles={cmsData.homePageArticles}
            buttonLabel={tt.latestSectionButtonText}
            buttonLink={tt.latestSectionButtonLink}
          />
        ) : (
          <SixLinksSection
            title={tt.latestSectionTitle}
            firstLabel={tt.latestSectionLinkOneText}
            firstLink={tt.latestSectionLinkOneUrl}
            secondLabel={tt.latestSectionLinkTwoText}
            secondLink={tt.latestSectionLinkTwoUrl}
            thirdLabel={tt.latestSectionLinkThreeText}
            thirdLink={tt.latestSectionLinkThreeUrl}
            fourthLabel={tt.latestSectionLinkFourText}
            fourthLink={tt.latestSectionLinkFourUrl}
            fifthLabel={tt.latestSectionLinkFiveText}
            fifthLink={tt.latestSectionLinkFiveUrl}
            sixthLabel={tt.latestSectionLinkSixText}
            sixthLink={tt.latestSectionLinkSixUrl}
            color="#E9F3ED"
          />
        )}

        <section className="w-full grid md:grid-flow-col grid-cols-1 mt-12 gap-y-4 md:gap-x-4">
          {!authLoading && loggedIn && userData && (
            <div className="w-full">
              <TrackingSection />
            </div>
          )}
        </section>

        <Divider />

        <LearnLinks
          title={tt.learnSectionTitle}
          buttonLabel={tt.learnSectionButtonTitle}
          buttonLink={tt.learnSectionButtonLink}
          firstLabel={tt.learnSectionLinkOneText}
          firstLink={tt.learnSectionLinkOneUrl}
          secondLabel={tt.learnSectionLinkTwoText}
          secondLink={tt.learnSectionLinkTwoUrl}
          thirdLabel={tt.learnSectionLinkThreeText}
          thirdLink={tt.learnSectionLinkThreeUrl}
          fourthLabel={tt.learnSectionLinkFourText}
          fourthLink={tt.learnSectionLinkFourUrl}
        />

        <Divider />

        {isActiveCarouselLoaded === false && (
          <MealPlanCTA
            title={tt.noActiveMealPlanCtaTitle}
            body={tt.noActiveMealPlanCtaText}
            cta={tt.noActiveMealPlanCtaFirstButtonText}
            ctaLink={tt.noActiveMealPlanCtaFirstButtonLink}
            learnMore={tt.noActiveMealPlanCtaSecondButtonText}
            learnMoreLink={tt.noActiveMealPlanCtaSecondButtonLink}
          />
        )}

        {/* Recipe suggestions if active meal plan */}
        {isActiveCarouselLoaded === true && (
          <>
            <section className="mt-l">
              <MealtimeRecipeSuggestions
                ideas={tt.ideasText}
                refreshButtonLabel={tt.refreshText}
                personalizeButtonLabel={tt.personalizeText}
              />
            </section>

            <Divider />
          </>
        )}

        <SixLinksSection
          title={tt.recipeCollectionsSectionTitle}
          buttonLabel={tt.recipeCollectionsButtonText}
          buttonLink={tt.recipeCollectionsButtonLink}
          firstLabel={tt.recipeCollectionsSectionLinkOneText}
          firstLink={tt.recipeCollectionsSectionLinkOneUrl}
          secondLabel={tt.recipeCollectionsSectionLinkTwoText}
          secondLink={tt.recipeCollectionsSectionLinkTwoUrl}
          thirdLabel={tt.recipeCollectionsSectionLinkThreeText}
          thirdLink={tt.recipeCollectionsSectionLinkThreeUrl}
          fourthLabel={tt.recipeCollectionsSectionLinkFourText}
          fourthLink={tt.recipeCollectionsSectionLinkFourUrl}
          fifthLabel={tt.recipeCollectionsSectionLinkFiveText}
          fifthLink={tt.recipeCollectionsSectionLinkFiveUrl}
          sixthLabel={tt.recipeCollectionsSectionLinkSixText}
          sixthLink={tt.recipeCollectionsSectionLinkSixUrl}
        />

        <Divider />

        <MyFavoriteRecipes
          setIsFavoriteRecipesLoaded={setIsFavoriteRecipesLoaded}
          className={`w-full mt-l ${isFavoriteRecipesLoaded === false ? "hidden" : ""}`}
          title={tt?.favoritesTitle}
          viewAll={tt?.favoritesViewAllButtonText}
        />

        {isFavoriteRecipesLoaded === true && <Divider />}

        <GetHealthySection
          title={tt.getHealthySectionTitle}
          firstLabel={tt.getHealthySectionLinkOneText}
          firstLink={tt.getHealthySectionLinkOneUrl}
          secondLabel={tt.getHealthySectionLinkTwoText}
          secondLink={tt.getHealthySectionLinkTwoUrl}
          thirdLabel={tt.getHealthySectionLinkThreeText}
          thirdLink={tt.getHealthySectionLinkThreeUrl}
          fourthLabel={tt.getHealthySectionLinkFourText}
          fourthLink={tt.getHealthySectionLinkFourUrl}
          fifthLabel={tt.getHealthySectionLinkFiveText}
          fifthLink={tt.getHealthySectionLinkFiveUrl}
        />

        <Divider />

        <SixLinksSection
          title={tt.overcomeStrugglesSectionTitle}
          firstLabel={tt.overcomeCollectionsSectionLinkOneText}
          firstLink={tt.overcomeCollectionsSectionLinkOneUrl}
          secondLabel={tt.overcomeCollectionsSectionLinkTwoText}
          secondLink={tt.overcomeCollectionsSectionLinkTwoUrl}
          thirdLabel={tt.overcomeCollectionsSectionLinkThreeText}
          thirdLink={tt.overcomeCollectionsSectionLinkThreeUrl}
          fourthLabel={tt.overcomeCollectionsSectionLinkFourText}
          fourthLink={tt.overcomeCollectionsSectionLinkFourUrl}
          fifthLabel={tt.overcomeCollectionsSectionLinkFiveText}
          fifthLink={tt.overcomeCollectionsSectionLinkFiveUrl}
          sixthLabel={tt.overcomeCollectionsSectionLinkSixText}
          sixthLink={tt.overcomeCollectionsSectionLinkSixUrl}
        />

        <Divider />

        <ChallengesSection
          title={tt.challengesSectionTitle}
          firstLink={tt.challengesSectionLinkOneUrl}
          firstLabel={tt.challengesSectionLinkOneLabel}
          firstText={tt.challengesSectionLinkOneTitle}
          secondLink={tt.challengesSectionLinkTwoUrl}
          secondLabel={tt.challengesSectionLinkTwoLabel}
          secondText={tt.challengesSectionLinkTwoTitle}
          thirdLink={tt.challengesSectionLinkThreeUrl}
          thirdLabel={tt.challengesSectionLinkThreeLabel}
          thirdText={tt.challengesSectionLinkThreeTitle}
        />
      </div>
    </>
  );
};

// ts-prune-ignore-next
export const query = graphql`
  query LoggedInHome($locale: String) {
    directus {
      homePageArticles(filter: { language: { _eq: $locale } }) {
        id
        url
        title
        label
        image {
          id
          filename_disk
        }
      }
    }
  }
`;

function useTranslations() {
  return {
    seoTitle: t({
      id: "LoggedInHome.seoTitle",
      message: "Homepage - Diet Doctor",
    }),
    seoDescription: t({
      id: "LoggedInHome.seoDescription",
      message: "Logged-in Homepage",
    }),
    morningGreeting: t({
      id: "LoggedInHome.morningGreeting",
      message: "Good morning",
    }),
    afternoonGreeting: t({
      id: "LoggedInHome.afternoonGreeting",
      message: "Good afternoon",
    }),
    eveningGreeting: t({
      id: "LoggedInHome.eveningGreeting",
      message: "Good evening",
    }),
    welcome: t({
      id: "LoggedInHome.welcome",
      message: "Welcome",
    }),
    myMealPlansButtonText: t({
      id: "LoggedInHome.myMealPlansButtonText",
      message: "My meal plans",
    }),
    nextMealsTitle: t({
      id: "LoggedInHome.nextMealsTitle",
      message: "Next meals",
    }),
    minutesSufix: t({
      id: "LoggedInHome.minutesSufix",
      message: "min",
    }),
    noActiveMealPlanCtaTitle: t({
      id: "LoggedInHome.noActiveMealPlanCtaTitle",
      message: "Meal plans designed for your results",
    }),
    noActiveMealPlanCtaText: t({
      id: "LoggedInHome.noActiveMealPlanCtaText",
      message: "We do the planning so you can focus on cooking and enjoying healthy, delicious food.",
    }),
    noActiveMealPlanCtaFirstButtonText: t({
      id: "LoggedInHome.noActiveMealPlanCtaFirstButtonText",
      message: "Get my meal plan",
    }),
    noActiveMealPlanCtaFirstButtonLink: t({
      id: "LoggedInHome.noActiveMealPlanCtaFirstButtonLink",
      message: "https://www.dietdoctor.com/low-carb/meal-plans/personalization",
    }),
    noActiveMealPlanCtaSecondButtonText: t({
      id: "LoggedInHome.noActiveMealPlanCtaSecondButtonText",
      message: "Learn more",
    }),
    noActiveMealPlanCtaSecondButtonLink: t({
      id: "LoggedInHome.noActiveMealPlanCtaSecondButtonLink",
      message: "https://www.dietdoctor.com/low-carb/meal-plans/personalization",
    }),
    activeMealPlanTitle: t({
      id: "LoggedInHome.activeMealPlanTitle",
      message: "My next meal",
    }),
    activeMealPlanButtonText: t({
      id: "LoggedInHome.activeMealPlanButtonText",
      message: "View full plan",
    }),
    latestSectionTitle: t({
      id: "LoggedInHome.latestSectionTitle",
      message: "Latest",
    }),
    latestSectionButtonText: t({
      id: "LoggedInHome.latestSectionButtonText",
      message: "View all",
    }),
    latestSectionButtonLink: t({
      id: "LoggedInHome.latestSectionButtonLink",
      message: "https://www.dietdoctor.com/news",
    }),
    latestSectionLinkOneText: t({
      id: "LoggedInHome.latestSectionLinkOneText",
      message: "News",
    }),
    latestSectionLinkOneUrl: t({
      id: "LoggedInHome.latestSectionLinkOneUrl",
      message: "https://www.dietdoctor.com/news",
    }),
    latestSectionLinkTwoText: t({
      id: "LoggedInHome.latestSectionLinkTwoText",
      message: "Recipes",
    }),
    latestSectionLinkTwoUrl: t({
      id: "LoggedInHome.latestSectionLinkTwoUrl",
      message: "https://www.dietdoctor.com/low-carb/recipes#new",
    }),
    latestSectionLinkThreeText: t({
      id: "LoggedInHome.latestSectionLinkThreeText",
      message: "Podcasts",
    }),
    latestSectionLinkThreeUrl: t({
      id: "LoggedInHome.latestSectionLinkThreeUrl",
      message: "https://www.dietdoctor.com/podcast",
    }),
    latestSectionLinkFourText: t({
      id: "LoggedInHome.latestSectionLinkFourText",
      message: "Videos",
    }),
    latestSectionLinkFourUrl: t({
      id: "LoggedInHome.latestSectionLinkFourUrl",
      message: "https://www.dietdoctor.com/video",
    }),
    latestSectionLinkFiveText: t({
      id: "LoggedInHome.latestSectionLinkFiveText",
      message: "Meal plans",
    }),
    latestSectionLinkFiveUrl: t({
      id: "LoggedInHome.latestSectionLinkFiveUrl",
      message: "https://www.dietdoctor.com/low-carb/meal-plans/all",
    }),
    latestSectionLinkSixText: t({
      id: "LoggedInHome.latestSectionLinkSixText",
      message: "Success stories",
    }),
    latestSectionLinkSixUrl: t({
      id: "LoggedInHome.latestSectionLinkSixUrl",
      message: "https://www.dietdoctor.com/low-carb/success-stories",
    }),
    ideasText: t({
      id: "LoggedInHome.ideasText",
      message: "ideas",
    }),
    refreshText: t({
      id: "LoggedInHome.refreshText",
      message: "Refresh",
    }),
    personalizeText: t({
      id: "LoggedInHome.personalizeText",
      message: "Personalize",
    }),
    recipeCollectionsSectionTitle: t({
      id: "LoggedInHome.recipeCollectionsSectionTitle",
      message: "Recipe collections",
    }),
    recipeCollectionsButtonText: t({
      id: "LoggedInHome.recipeCollectionsButtonText",
      message: "View all ",
    }),
    recipeCollectionsButtonLink: t({
      id: "LoggedInHome.recipeCollectionsButtonLink",
      message: "https://www.dietdoctor.com/low-carb/recipes/collections",
    }),
    recipeCollectionsSectionLinkOneText: t({
      id: "LoggedInHome.recipeCollectionsSectionLinkOneText",
      message: "On a budget",
    }),
    recipeCollectionsSectionLinkOneUrl: t({
      id: "LoggedInHome.recipeCollectionsSectionLinkOneUrl",
      message: "https://www.dietdoctor.com/low-carb/recipes/low-carb-budget",
    }),
    recipeCollectionsSectionLinkTwoText: t({
      id: "LoggedInHome.recipeCollectionsSectionLinkTwoText",
      message: "High protein",
    }),
    recipeCollectionsSectionLinkTwoUrl: t({
      id: "LoggedInHome.recipeCollectionsSectionLinkTwoUrl",
      message: "https://www.dietdoctor.com/high-protein/recipes",
    }),
    recipeCollectionsSectionLinkThreeText: t({
      id: "LoggedInHome.recipeCollectionsSectionLinkThreeText",
      message: "Top 30",
    }),
    recipeCollectionsSectionLinkThreeUrl: t({
      id: "LoggedInHome.recipeCollectionsSectionLinkThreeUrl",
      message: "https://www.dietdoctor.com/low-carb/recipes/top-30",
    }),
    recipeCollectionsSectionLinkFourText: t({
      id: "LoggedInHome.recipeCollectionsSectionLinkFourText",
      message: "Chicken",
    }),
    recipeCollectionsSectionLinkFourUrl: t({
      id: "LoggedInHome.recipeCollectionsSectionLinkFourUrl",
      message: "https://www.dietdoctor.com/low-carb/recipes/keto-chicken",
    }),
    recipeCollectionsSectionLinkFiveText: t({
      id: "LoggedInHome.recipeCollectionsSectionLinkFiveText",
      message: "Family-friendly",
    }),
    recipeCollectionsSectionLinkFiveUrl: t({
      id: "LoggedInHome.recipeCollectionsSectionLinkFiveUrl",
      message: "https://www.dietdoctor.com/low-carb/recipes/families",
    }),
    recipeCollectionsSectionLinkSixText: t({
      id: "LoggedInHome.recipeCollectionsSectionLinkSixText",
      message: "No-cook",
    }),
    recipeCollectionsSectionLinkSixUrl: t({
      id: "LoggedInHome.recipeCollectionsSectionLinkSixUrl",
      message: "https://www.dietdoctor.com/low-carb/recipes/meals/no-cooking",
    }),
    learnSectionTitle: t({
      id: "LoggedInHome.learnSectionTitle",
      message: "Learn",
    }),
    learnSectionButtonTitle: t({
      id: "LoggedInHome.learnSectionButtonTitle",
      message: "View all",
    }),
    learnSectionButtonLink: t({
      id: "LoggedInHome.learnSectionButtonLink",
      message: "https://www.dietdoctor.com/low-carb/guides",
    }),
    learnSectionLinkOneText: t({
      id: "LoggedInHome.learnSectionLinkOneText",
      message: "Healthy weight loss",
    }),
    learnSectionLinkOneUrl: t({
      id: "LoggedInHome.learnSectionLinkOneUrl",
      message: "https://www.dietdoctor.com/weight-loss",
    }),
    learnSectionLinkTwoText: t({
      id: "LoggedInHome.learnSectionLinkTwoText",
      message: "Keto for beginners",
    }),
    learnSectionLinkTwoUrl: t({
      id: "LoggedInHome.learnSectionLinkTwoUrl",
      message: "https://www.dietdoctor.com/low-carb/keto",
    }),
    learnSectionLinkThreeText: t({
      id: "LoggedInHome.learnSectionLinkThreeText",
      message: "Low carb for beginners",
    }),
    learnSectionLinkThreeUrl: t({
      id: "LoggedInHome.learnSectionLinkThreeUrl",
      message: "https://www.dietdoctor.com/low-carb",
    }),
    learnSectionLinkFourText: t({
      id: "LoggedInHome.learnSectionLinkFourText",
      message: "High-protein diet",
    }),
    learnSectionLinkFourUrl: t({
      id: "LoggedInHome.learnSectionLinkFourUrl",
      message: "https://www.dietdoctor.com/high-protein",
    }),
    favoritesTitle: t({
      id: "LoggedInHome.favoritesTitle",
      message: "My favorites",
    }),
    favoritesViewAllButtonText: t({
      id: "LoggedInHome.favoritesViewAllButtonText",
      message: "View all",
    }),
    getHealthySectionTitle: t({
      id: "LoggedInHome.getHealthySectionTitle",
      message: "Get healthy",
    }),
    getHealthySectionLinkOneText: t({
      id: "LoggedInHome.getHealthySectionLinkOneText",
      message: "Diabetes",
    }),
    getHealthySectionLinkOneUrl: t({
      id: "LoggedInHome.getHealthySectionLinkOneUrl",
      message: "https://www.dietdoctor.com/diabetes",
    }),
    getHealthySectionLinkTwoText: t({
      id: "LoggedInHome.getHealthySectionLinkTwoText",
      message: "High blood pressure",
    }),
    getHealthySectionLinkTwoUrl: t({
      id: "LoggedInHome.getHealthySectionLinkTwoUrl",
      message: "https://www.dietdoctor.com/blood-pressure",
    }),
    getHealthySectionLinkThreeText: t({
      id: "LoggedInHome.getHealthySectionLinkThreeText",
      message: "PCOS",
    }),
    getHealthySectionLinkThreeUrl: t({
      id: "LoggedInHome.getHealthySectionLinkThreeUrl",
      message: "https://www.dietdoctor.com/low-carb/benefits/pcos",
    }),
    getHealthySectionLinkFourText: t({
      id: "LoggedInHome.getHealthySectionLinkFourText",
      message: "IBS",
    }),
    getHealthySectionLinkFourUrl: t({
      id: "LoggedInHome.getHealthySectionLinkFourUrl",
      message: "https://www.dietdoctor.com/low-carb/benefits/ibs",
    }),
    getHealthySectionLinkFiveText: t({
      id: "LoggedInHome.getHealthySectionLinkFiveText",
      message: "Cholesterol",
    }),
    getHealthySectionLinkFiveUrl: t({
      id: "LoggedInHome.getHealthySectionLinkFiveUrl",
      message: "https://www.dietdoctor.com/low-carb/cholesterol-basics",
    }),
    overcomeStrugglesSectionTitle: t({
      id: "LoggedInHome.overcomeStrugglesSectionTitle",
      message: "Overcome struggles",
    }),
    overcomeStrugglesSectionButtonTitle: t({
      id: "LoggedInHome.overcomeStrugglesSectionButtonTitle",
      message: "View all",
    }),
    overcomeStrugglesSectionButtonLink: t({
      id: "LoggedInHome.overcomeStrugglesSectionButtonLink",
      message: "/",
    }),
    overcomeCollectionsSectionLinkOneText: t({
      id: "LoggedInHome.overcomeCollectionsSectionLinkOneText",
      message: "Hunger",
    }),
    overcomeCollectionsSectionLinkOneUrl: t({
      id: "LoggedInHome.overcomeCollectionsSectionLinkOneUrl",
      message: "https://www.dietdoctor.com/low-carb/hunger",
    }),
    overcomeCollectionsSectionLinkTwoText: t({
      id: "LoggedInHome.overcomeCollectionsSectionLinkTwoText",
      message: "Alcohol",
    }),
    overcomeCollectionsSectionLinkTwoUrl: t({
      id: "LoggedInHome.overcomeCollectionsSectionLinkTwoUrl",
      message: "https://www.dietdoctor.com/low-carb/keto/alcohol",
    }),
    overcomeCollectionsSectionLinkThreeText: t({
      id: "LoggedInHome.overcomeCollectionsSectionLinkThirdText",
      message: "Eating out",
    }),
    overcomeCollectionsSectionLinkThreeUrl: t({
      id: "LoggedInHome.overcomeCollectionsSectionLinkThirdUrl",
      message: "https://www.dietdoctor.com/low-carb/dining-out",
    }),
    overcomeCollectionsSectionLinkFourText: t({
      id: "LoggedInHome.overcomeCollectionsSectionLinkFourText",
      message: "Cravings",
    }),
    overcomeCollectionsSectionLinkFourUrl: t({
      id: "LoggedInHome.overcomeCollectionsSectionLinkFourUrl",
      message: "https://www.dietdoctor.com/member/courses/jonsson",
    }),
    overcomeCollectionsSectionLinkFiveText: t({
      id: "LoggedInHome.overcomeCollectionsSectionLinkFiveText",
      message: "Slow progress",
    }),
    overcomeCollectionsSectionLinkFiveUrl: t({
      id: "LoggedInHome.overcomeCollectionsSectionLinkFiveUrl",
      message: "https://www.dietdoctor.com/weight-loss/realistic-expectations",
    }),
    overcomeCollectionsSectionLinkSixText: t({
      id: "LoggedInHome.overcomeCollectionsSectionLinkSixText",
      message: "Weight stall",
    }),
    overcomeCollectionsSectionLinkSixUrl: t({
      id: "LoggedInHome.overcomeCollectionsSectionLinkSixUrl",
      message: "https://www.dietdoctor.com/weight-loss/break-a-stall",
    }),
    challengesSectionTitle: t({
      id: "LoggedInHome.challengesSectionTitle",
      message: "Weight loss programs",
    }),
    challengesSectionLinkOneLabel: t({
      id: "LoggedInHome.challengesSectionLinkOneLabel",
      message: "2 WEEKS",
    }),
    challengesSectionLinkOneTitle: t({
      id: "LoggedInHome.challengesSectionLinkOneTitle",
      message: "Get Started with Keto",
    }),
    challengesSectionLinkOneUrl: t({
      id: "LoggedInHome.challengesSectionLinkOneUrl",
      message: "https://www.dietdoctor.com/low-carb/get-started",
    }),
    challengesSectionLinkTwoLabel: t({
      id: "LoggedInHome.challengesSectionLinkTwoLabel",
      message: "5 WEEKS",
    }),
    challengesSectionLinkTwoTitle: t({
      id: "LoggedInHome.challengesSectionLinkTwoTitle",
      message: "Keto with Kristie",
    }),
    challengesSectionLinkTwoUrl: t({
      id: "LoggedInHome.challengesSectionLinkTwoUrl",
      message: "https://www.dietdoctor.com/low-carb/keto/5-weeks",
    }),
    challengesSectionLinkThreeLabel: t({
      id: "LoggedInHome.challengesSectionLinkThreeLabel",
      message: "10 WEEKS",
    }),
    challengesSectionLinkThreeTitle: t({
      id: "LoggedInHome.challengesSectionLinkThreeTitle",
      message: "Weight Loss for Good",
    }),
    challengesSectionLinkThreeUrl: t({
      id: "LoggedInHome.challengesSectionLinkThreeUrl",
      message: "https://www.dietdoctor.com/how-to-lose-weight/10-weeks",
    }),
    quickLinksSectionTitle: t({
      id: "LoggedInHome.quickLinksSectionTitle",
      message: "Quick links",
    }),
    quickLinksSectionLinkOneTitle: t({
      id: "LoggedInHome.quickLinksSectionLinkOneTitle",
      message: "Recipes",
    }),
    quickLinksSectionLinkOneUrl: t({
      id: "LoggedInHome.quickLinksSectionLinkOneUrl",
      message: "/low-carb/recipes",
    }),
    quickLinksSectionLinkTwoTitle: t({
      id: "LoggedInHome.quickLinksSectionLinkTwoTitle",
      message: "Meal plans",
    }),
    quickLinksSectionLinkTwoUrl: t({
      id: "LoggedInHome.quickLinksSectionLinkTwoUrl",
      message: "/low-carb/meal-plans/all",
    }),
    quickLinksSectionLinkThreeTitle: t({
      id: "LoggedInHome.quickLinksSectionLinkThreeTitle",
      message: "Videos",
    }),
    quickLinksSectionLinkThreeUrl: t({
      id: "LoggedInHome.quickLinksSectionLinkThreeUrl",
      message: "/video",
    }),
    quickLinksSectionLinkFourTitle: t({
      id: "LoggedInHome.quickLinksSectionLinkFourTitle",
      message: "Guides",
    }),
    quickLinksSectionLinkFourUrl: t({
      id: "LoggedInHome.quickLinksSectionLinkFourUrl",
      message: "/low-carb/guides",
    }),
    quickLinksSectionLinkFiveTitle: t({
      id: "LoggedInHome.quickLinksSectionLinkFiveTitle",
      message: "App",
    }),
    quickLinksSectionLinkFiveUrl: t({
      id: "LoggedInHome.quickLinksSectionLinkFiveUrl",
      message: "/app",
    }),
    quickLinksSectionLinkSixTitle: t({
      id: "LoggedInHome.quickLinksSectionLinkSixTitle",
      message: "Member benefits",
    }),
    quickLinksSectionLinkSixUrl: t({
      id: "LoggedInHome.quickLinksSectionLinkSixUrl",
      message: "/explore-dd-plus",
    }),
  };
}

// ts-prune-ignore-next
export default LoggedInHomePage;

type LoggedInHomeData = {
  directus: {
    homePageArticles: Array<{
      id: string;
      url: string;
      title: string;
      label: string;
      image: {
        id: string;
        filename_disk: string;
      };
    }>;
  };
};
