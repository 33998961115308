import differenceInMonths from "date-fns/esm/fp/differenceInMonths";
import parseISO from "date-fns/esm/fp/parseISO";
import subMonths from "date-fns/esm/fp/subMonths";
import React, { ReactNode, useCallback, useMemo } from "react";
import { ShepherdTour } from "react-shepherd";
import { useStateWithLocalStorage } from "../../../../hooks/useStateWithLocalStorage/useStateWithLocalStorage";
import steps from "./tour/steps.json";
import { toShepherdOptionsWithType, TourStep } from "./tour/stepsUtils";
import { TourHandler } from "./tour/Tour";

type Props = {
  isAdminPays: boolean;
  children: ReactNode;
};

const TOUR_INTERVAL = 3; // months
const withoutMemberPays = (s: TourStep) => (s.memberPays ? false : true);

export function DDProTour({ isAdminPays, children }: Props) {
  const [tourDoneAt, setTourDoneAt] = useStateWithLocalStorage<string>(
    "dd/pro/tour/done-at",
    subMonths(TOUR_INTERVAL, new Date()).toISOString() // if the tour was never taken, we set the date to (today - TOUR_INTERVAL months)
  );

  // the number of months since the last time we saw the tour
  const months = useMemo(() => differenceInMonths(parseISO(tourDoneAt), new Date()), [tourDoneAt]);

  const shouldDisplayTour = useMemo(() => months >= TOUR_INTERVAL, [months]);

  const setTourDone = useCallback(() => {
    setTourDoneAt(new Date().toISOString());
  }, []);

  const tourSteps = useMemo(() => {
    // `steps` should be update based on `isAdminPays` because it is async
    // and the steps are not the same for member pays
    const allTourSteps = steps as TourStep[];
    if (isAdminPays) {
      return allTourSteps.filter(withoutMemberPays).map(toShepherdOptionsWithType);
    }
    return allTourSteps.map(toShepherdOptionsWithType);
  }, [isAdminPays]);

  if (!shouldDisplayTour) {
    return <>{children}</>;
  }

  return (
    // @ts-ignore
    <ShepherdTour
      steps={tourSteps}
      tourOptions={{
        defaultStepOptions: {
          cancelIcon: {
            enabled: true,
          },
        },
        useModalOverlay: true,
      }}
    >
      <>{children}</>
      <TourHandler isAdminPays={isAdminPays} setTourDone={setTourDone} />
    </ShepherdTour>
  );
}
