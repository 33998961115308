import React, { useMemo } from "react";
import Imgix from "react-imgix";
import ClockIcon from "../../../icons/ClockIcon";
import CarbsBadge from "../../../components/CarbsBadge/CarbsBadge";
import { Link } from "../../../components/Link/Link";
import { RecipePage } from "../../../pages";
import { BaseRecipe } from "../../../types/BaseRecipe";
import { convertRatingValueToOneDecimal } from "../../../utils/fraction";
import { getRecipeTime } from "../../../utils/recipe";

export default function SingleRecipePreview({ recipe }: { recipe?: BaseRecipe }) {
  const recipeCookingTime = useMemo(() => `${getRecipeTime(recipe?.time)} min`, [recipe]);

  if (!recipe) {
    return null;
  }

  return (
    <li key={recipe.slug} className="w-full lg:w-1/2 mt-m">
      <Link
        to={RecipePage}
        params={{ slug: recipe.slug }}
        style={{ color: "inherit" }}
        className="flex flex-row"
      >
        <div
          className="relative p-0 w-44 sm:w-64 max-w-full shrink-0 pr-1 lg:pr-0"
          style={{ maxWidth: "46.875vw" }}
        >
          <div className="relative aspect-[1/1] sm:aspect-[16/10]">
            <Imgix
              className="object-cover overflowfix rounded-xl bg-lighter-grey h-full w-full"
              src={`https://i.dietdoctor.com${recipe!.images.hz}?auto=compress%2Cformat&fit=crop`}
              htmlAttributes={{
                alt: "recipe",
              }}
              width={360}
              height={240}
            />
          </div>
          <CarbsBadge recipe={recipe} className="absolute left-2 bottom-2" />
        </div>

        <div className="flex flex-col justify-start ml-m mr-m my-m">
          <h4 className="m-0 label-xl line-clamp-3">{recipe.title.replace("&amp;", "&")}</h4>
          <p className="m-0 mt-1 flex flex-row items-center label-s font-normal">
            <span className="label-m" style={{ color: "#FDD942" }}>
              ★
            </span>

            <span className="ml-1 opacity-60">{convertRatingValueToOneDecimal(recipe.rating)}</span>

            <span className="border-l border-grey mx-3" style={{ height: "80%" }}></span>

            <ClockIcon height={18} width={18} className="opacity-60" style={{ padding: "1px 0" }} />
            <span className="ml-0.5 opacity-60">{recipeCookingTime}</span>
          </p>
          <p className="m-0 mt-1 flex flex-row items-center text-black font-medium text-md"></p>
        </div>
      </Link>
    </li>
  );
}
