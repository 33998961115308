import { useCallback, useEffect, useMemo, useState } from "react";
import GetUsers from "../../graphql/DDPro/GetUsers.graphql";
import { GetUsers_getDDProMembers } from "../../types/GetUsers";
import { MembershipSubscriptionStatus } from "../../types/graphql-global-types";
import { client } from "../../utils/apollo-client";
import { useUser } from "../useUser/useUser";

type ProMember = GetUsers_getDDProMembers;

const hasActiveSubscription = (u: ProMember) => u.subscriptionStatus === MembershipSubscriptionStatus.ACTIVE;

export function useDDProMembers() {
  const { user } = useUser();
  const [members, setMembers] = useState<ProMember[]>([]);
  const numTotal = useMemo(() => members.length, [members]);
  const numActive = useMemo(() => members.filter(hasActiveSubscription).length, [members]);

  const fetchMembers = useCallback(() => {
    client
      .query<{ getDDProMembers: ProMember[] }>({
        query: GetUsers,
        variables: { input: { groupId: user?.pro?.group.id } },
        fetchPolicy: "network-only",
      })
      .then((res) => setMembers(res.data.getDDProMembers));
  }, [user?.pro?.group.id]);

  useEffect(() => {
    if (user?.pro?.group.id) {
      fetchMembers();
    }
  }, [user?.pro?.group.id, fetchMembers]);

  return { members, numTotal, numActive, refresh: fetchMembers };
}
