import React from "react";
import { useDDProTranslations } from "../../../dd-pro/useDDProTranslations";
import { Link } from "../../../../components/Link/Link";
import { createExternalPage } from "../../../../page-utils";

export function CMECourseBlock() {
  const tt = useDDProTranslations();
  return (
    <div className="tour-5 rounded-md bg-lightest-grey flex flex-col p-6 mt-2">
      <div className="flex justify-between items-baseline">
        <div className="text-2xl tracking-tight">{tt.cmeCourse}</div>
        <Link to={CMEPage} className="text-base font-medium hover:opacity-75 transition-opacity">
          {tt.viewDetails}
        </Link>
      </div>
      <div className="flex flex-col">
        <p>{tt.treatingMetabolicSyndromeType2DiabetesAndObesityWithTherapeuticCarbohydrateRestriction}</p>
        <div className="flex justify-center">
          <Link
            to={CMERegistrationPage}
            className="hover:bg-light-blue border-2 border-light-blue text-light-blue hover:text-white rounded-full py-2 px-12 flex justify-center transition-all text-base font-medium"
          >
            {tt.accessNow}
          </Link>
        </div>
      </div>
    </div>
  );
}

const CMEPage = createExternalPage({
  name: "cme",
  path: () => "/cme1",
});

const CMERegistrationPage = createExternalPage({
  name: "cme-registration",
  path: () => "/cme-registration",
});
