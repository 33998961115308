import { useQuery } from "@apollo/client";
import {
  GetOnboardingPreferences,
  GetOnboardingPreferences_getUser,
} from "../../../types/GetOnboardingPreferences";
import GetOnboardingPreferencesQuery from "../../../graphql/onboarding/GetOnboardingPreferences.graphql";
import { useEffect, useMemo, useState } from "react";

export const useOnboardingPreferences = () => {
  const [preferences, setPreferences] = useState<GetOnboardingPreferences_getUser>();
  const { data: onboardingData } = useQuery<GetOnboardingPreferences>(GetOnboardingPreferencesQuery, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    setPreferences(onboardingData?.getUser);
  }, [onboardingData]);

  const hasPreferences = useMemo(() => {
    return Object.entries(preferences ?? {})
      .filter(([prop]) => prop !== "__typename")
      .some(([, value]) => value !== null);
  }, [preferences]);

  return {
    preferences,
    hasPreferences,
  };
};
