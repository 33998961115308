import { useField, FieldInputProps, FormikProps } from "formik";
import * as React from "react";
import { useMemo } from "react";
import { useDDProTranslations } from "../useDDProTranslations";

export const EmailInput = ({
  title = "",
  ...props
}: { field: FieldInputProps<string>; title: string } & FormikProps<{}>) => {
  const tt = useDDProTranslations();
  const hasTitle = useMemo(() => title !== "", [title]);
  const [field, meta] = useField("email");
  return (
    <div className="flex flex-col">
      <div className="flex items-baseline justify-between">
        {hasTitle ? (
          <label htmlFor={field.name} className="font-medium py-2 text-base">
            {tt.email}
          </label>
        ) : (
          <div />
        )}
      </div>
      <input
        placeholder={!hasTitle ? tt.email : ""}
        tabIndex={0}
        className={`${
          meta.touched && meta.error ? "border-pink border-2" : "border-grey border"
        } focus:bg-white transition-all focus:ring-2 focus:ring-grey focus:shadow-md mb-4 px-4 py-3 rounded-lg w-full flex-1 appearance-none placeholder-grey focus:outline-none focus:border-transparent`}
        autoComplete="off"
        {...field}
        {...props}
      />
    </div>
  );
};
