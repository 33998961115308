import React, { useContext } from "react";
import { pageContext } from "../../../components/PageProvider/PageProvider";
import { Locale } from "../../../types/Locale";

export default function SixLinksSection({
  title,
  buttonLabel,
  buttonLink,
  firstLabel,
  firstLink,
  secondLabel,
  secondLink,
  thirdLabel,
  thirdLink,
  fourthLabel,
  fourthLink,
  fifthLabel,
  fifthLink,
  sixthLabel,
  sixthLink,
  color,
}: {
  title: string;
  buttonLabel?: string;
  buttonLink?: string;
  firstLabel: string;
  firstLink: string;
  secondLabel?: string;
  secondLink?: string;
  thirdLabel?: string;
  thirdLink?: string;
  fourthLabel?: string;
  fourthLink?: string;
  fifthLabel?: string;
  fifthLink?: string;
  sixthLabel?: string;
  sixthLink?: string;
  color?: string;
}) {
  const { locale } = useContext(pageContext);
  const isSixthExists = locale === Locale.EN && sixthLink && sixthLabel;
  return (
    <div className="mt-l">
      <div className="flex flex-row justify-between items-center">
        <h2 className="display-xs m-0">{title}</h2>
        {buttonLink && buttonLabel && (
          <a
            href={buttonLink}
            className="label-m hover:underline"
            style={{ color: "#6187CD", minWidth: "52px" }}
          >
            {buttonLabel}
          </a>
        )}
      </div>

      <div className={`mt-m grid grid-cols-2 gap-2 ${!isSixthExists ? "lg:grid-cols-5" : "lg:grid-cols-6"}`}>
        <a
          href={firstLink}
          className="label-l text-center py-3 rounded-lg w-full text-black hover:opacity-75"
          style={{ backgroundColor: color ? color : "#E7EBF2" }}
        >
          {firstLabel}
        </a>
        {secondLink && secondLabel && (
          <a
            href={secondLink}
            className="label-l text-center py-3 rounded-lg w-full text-black hover:opacity-75"
            style={{ backgroundColor: color ? color : "#E7EBF2" }}
          >
            {secondLabel}
          </a>
        )}

        {((thirdLink && thirdLabel) || (fourthLink && fourthLabel)) && (
          <>
            {thirdLink && thirdLabel && (
              <a
                href={thirdLink}
                className="label-l text-center py-3 rounded-lg w-full text-black hover:opacity-75"
                style={{ backgroundColor: color ? color : "#E7EBF2" }}
              >
                {thirdLabel}
              </a>
            )}
            {fourthLink && fourthLabel && (
              <a
                href={fourthLink}
                className="label-l text-center py-3 rounded-lg w-full text-black hover:opacity-75"
                style={{ backgroundColor: color ? color : "#E7EBF2" }}
              >
                {fourthLabel}
              </a>
            )}
          </>
        )}
        {((fifthLink && fifthLabel) || isSixthExists) && (
          <>
            {fifthLink && fifthLabel && (
              <a
                href={fifthLink}
                className="label-l text-center py-3 rounded-lg w-full text-black hover:opacity-75"
                style={{ backgroundColor: color ? color : "#E7EBF2" }}
              >
                {fifthLabel}
              </a>
            )}
            {isSixthExists && (
              <a
                href={sixthLink}
                className="label-l text-center py-3 rounded-lg w-full text-black hover:opacity-75"
                style={{ backgroundColor: color ? color : "#E7EBF2" }}
              >
                {sixthLabel}
              </a>
            )}
          </>
        )}
      </div>
    </div>
  );
}
