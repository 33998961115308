import React, { useContext, useEffect, useState } from "react";
import { LoginContext } from "../../../components/LoginProvider/LoginProvider";
import GetFavoriteRecipes from "../../../graphql/useFavoriteRecipes/GetFavoriteRecipes.graphql";
import { useQuery } from "@apollo/client";
import { MyFavoritesPage } from "../../../pages";
import { Link } from "../../../components/Link/Link";
import SingleRecipePreview from "./SingleRecipePreview";
import SkeletonSingleRecipeSuggestions from "./SkeletonSingleRecipeSuggestions";

const MyFavoriteRecipes = ({
  setIsFavoriteRecipesLoaded,
  className,
  title,
  viewAll,
}: {
  setIsFavoriteRecipesLoaded: React.Dispatch<React.SetStateAction<boolean | null>>;
  className: string;
  title: string;
  viewAll: string;
}) => {
  const { loggedIn } = useContext(LoginContext);

  const [skip, setSkip] = useState(true);
  const [data, setData] = useState<any>(null);

  const {
    loading: favoritesLoading,
    data: favoritesData,
    error: favoritesError,
    refetch: getFavorites,
  } = useQuery(GetFavoriteRecipes, {
    skip: skip,
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    variables: { pageSize: 4 },
  });

  useEffect(() => {
    if (loggedIn) {
      setSkip(false);
      getFavorites();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  useEffect(() => {
    if (!favoritesLoading) {
      if (favoritesData && favoritesData?.favoriteRecipes?.recipes.length > 0) {
        setData(favoritesData);
        setIsFavoriteRecipesLoaded(true);
      } else if (
        (favoritesData && favoritesData?.favoriteRecipes?.recipes.length > 0 === false) ||
        favoritesError
      ) {
        setIsFavoriteRecipesLoaded(false);
      }
      setSkip(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favoritesLoading]);

  if (data) {
    return (
      <section className={className ? className : ""}>
        <div className="flex flex-row justify-between items-center">
          <h3 className="m-0 display-xs">{title}</h3>
          <Link to={MyFavoritesPage} className="label-m hover:underline" style={{ color: "#6187CD" }}>
            {viewAll}
          </Link>
        </div>
        <ul className="list-none flex flex-row flex-wrap m-0 p-0">
          {favoritesLoading
            ? Array(4)
                .fill(null)
                .map((item, index) => <SkeletonSingleRecipeSuggestions key={index} />)
            : data.favoriteRecipes.recipes.map((recipe: any) => (
                <SingleRecipePreview recipe={recipe} key={recipe.slug} />
              ))}
        </ul>
      </section>
    );
  } else {
    return null;
  }
};

export default React.memo(MyFavoriteRecipes);
