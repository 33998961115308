import React from "react";
import { Code } from "../../../dd-pro/pro/states/qr/Code";
import { useDDProTranslations } from "../../../dd-pro/useDDProTranslations";
import { Link } from "../../../../components/Link/Link";
import { DDProPDFPage } from "../../../../pages";

export function QRCodeBlock({ url }: { url: string }) {
  const tt = useDDProTranslations();
  return (
    <div className="tour-2 rounded-md bg-green-100 flex flex-col p-6">
      <div className="flex justify-between items-baseline">
        <div className="text-2xl tracking-tight">{tt.qrCode}</div>
        <Link to={DDProPDFPage} className="text-base font-medium hover:opacity-75 transition-opacity">
          Print QR
        </Link>
      </div>
      <div className="flex flex-col items-center">
        <div className="bg-white rounded-sm p-2 my-4 flex justify-center items-center">
          <Code width={140} url={url} />
        </div>
      </div>
    </div>
  );
}
