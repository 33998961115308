import { MealType } from "../types/graphql-global-types";

const getMealTime = () => {
  const hourlyTimeNow = new Date().getHours();

  let meal: MealType = MealType.BREAKFAST;

  switch (true) {
    case hourlyTimeNow < 11:
      meal = MealType.BREAKFAST;
      break;
    case hourlyTimeNow < 15:
      meal = MealType.LUNCH;
      break;
    case hourlyTimeNow < 21:
      meal = MealType.DINNER;
      break;
  }

  return meal;
};

export default getMealTime;
