import { IUser } from "@dietdoctor/elements";
import React, { useContext, useMemo } from "react";
import { joinGroupToUrl } from "../../../dd-pro/pro/states/qr/utils";
import { pageContext } from "../../../../components/PageProvider/PageProvider";
import { useDDProMembers } from "../../../../hooks/useDDProMembers/useDDProMembers";
import { useUser } from "../../../../hooks/useUser/useUser";
import { BenefitsBlock } from "./BenefitsBlock";
import { BretBlock } from "./BretBlock";
import { CMECourseBlock } from "./CMECourseBlock";
import { DDProTour } from "./DDProTour";
import { MembersCountBlock } from "./MembersCountBlock";
import { PopularResourcesBlock } from "./PopularResourcesBlock";
import ProLinksSection from "./ProLinksSection";
import { QRCodeBlock } from "./QRCodeBlock";
import { QuickAddBlock } from "./QuickAddBlock";

type IProUser = IUser["pro"];

export default function ProSection({ pro }: { pro: IProUser }) {
  const { locale } = useContext(pageContext);
  const { numTotal, numActive, refresh } = useDDProMembers();
  const { user } = useUser();
  const isAdminPays = useMemo(() => user?.pro?.group.subscriptionPayer === 1, [user?.pro]);
  const url = useMemo(
    () =>
      user?.pro
        ? joinGroupToUrl(locale, document.location.origin, user?.pro?.group.id, user?.pro?.group.title)
        : "",
    [user, locale]
  );

  const quickAddSize = useMemo(() => (isAdminPays ? "col-span-2" : "col-span-1"), [isAdminPays]);
  const memberCountSize = useMemo(() => (isAdminPays ? "col-span-2" : ""), [isAdminPays]);

  return (
    <section className="mt-6 ">
      <div className="text-dark-green font-medium text-lg">DD PRO</div>
      <div className="text-2xl md:text-5xl tracking-tight font-medium text-dark-grey">{pro?.group.title}</div>
      <DDProTour isAdminPays={isAdminPays}>
        <section className="tour grid md:grid-cols-3 gap-y-2 md:gap-x-2 mt-8">
          <div className={`tour-0 ${memberCountSize} md:col-span-1 flex w-full`}>
            <MembersCountBlock numTotal={numTotal} numActive={numActive} />
          </div>
          <div className={`tour-1 ${quickAddSize} flex w-full`}>
            <QuickAddBlock user={user} onQuickMemberAdded={refresh} isAdminPays={isAdminPays} />
          </div>
          {!isAdminPays && <QRCodeBlock url={url} />}
        </section>
        <section className="md:grid md:grid-cols-3 md:gap-2">
          <BenefitsBlock isAdminPays={isAdminPays} />
          <PopularResourcesBlock />
        </section>
        <section className="md:grid md:grid-cols-3 md:gap-2">
          <BretBlock />
          <CMECourseBlock />
        </section>
      </DDProTour>
      <ProLinksSection />
    </section>
  );
}
