import React, { useContext } from "react";
import { pageContext } from "../../../components/PageProvider/PageProvider";
import { Locale } from "../../../types/Locale";

export default function ChallengesSection({
  title,
  firstLabel,
  firstText,
  firstLink,
  secondLabel,
  secondText,
  secondLink,
  thirdLabel,
  thirdText,
  thirdLink,
}: {
  title: string;
  firstLabel: string;
  firstText: string;
  firstLink: string;
  secondLabel: string;
  secondText: string;
  secondLink: string;
  thirdLabel?: string;
  thirdText?: string;
  thirdLink?: string;
}) {
  const { locale } = useContext(pageContext);
  const isThirdExists = locale === Locale.EN && thirdLink && thirdLabel && thirdText;

  return (
    <div className="mt-l">
      <div className="flex flex-row justify-between items-center">
        <h2 className="m-0 display-xs">{title}</h2>
      </div>

      <div
        className={`flex flex-col items-center xl:flex-row mt-m space-y-4 xl:space-y-0 xl:space-x-2 ${
          !isThirdExists ? "justify-center" : "justify-between"
        } w-full text-white font-medium `}
      >
        <a
          href={firstLink}
          className={`text-center rounded-lg px-8 w-full flex flex-col justify-center items-center hover:opacity-75 ${
            !isThirdExists ? "xl:w-1/2" : "xl:w-1/3"
          }`}
          style={{ backgroundColor: "#516795", minHeight: "192px" }}
        >
          <span className="uppercase text-white opacity-50 label-xl font-medium mt-l mb-xs">{firstLabel}</span>

          <span className="display-m text-white mb-l">{firstText}</span>
        </a>
        <a
          href={secondLink}
          className={`text-center rounded-lg px-8 w-full flex flex-col justify-center items-center hover:opacity-75 ${
            !isThirdExists ? "xl:w-1/2" : "xl:w-1/3"
          }`}
          style={{ backgroundColor: "#516795", minHeight: "192px" }}
        >
          <span className="uppercase text-white opacity-50 label-xl font-medium mt-l mb-xs">{secondLabel}</span>

          <span className="display-m text-white mb-l">{secondText}</span>
        </a>
        {isThirdExists && (
          <a
            href={thirdLink}
            className="text-center rounded-lg px-8 w-full xl:w-1/3 flex flex-col justify-center items-center hover:opacity-75"
            style={{ backgroundColor: "#516795", minHeight: "192px" }}
          >
            <span className="uppercase text-white opacity-50 label-xl font-medium mt-l mb-xs">
              {thirdLabel}
            </span>

            <span className="display-m text-white mb-l">{thirdText}</span>
          </a>
        )}
      </div>
    </div>
  );
}
