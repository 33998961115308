import React from "react";

export default function TimeOfDayGreeting({
  name,
  morningGreeting,
  afternoonGreeting,
  eveningGreeting,
}: {
  name: string;
  morningGreeting?: string;
  afternoonGreeting?: string;
  eveningGreeting?: string;
}) {
  const hourlyTimeNow = new Date().getHours();

  let greeting = "Welcome";
  switch (true) {
    case hourlyTimeNow > 4 && hourlyTimeNow < 12:
      greeting = morningGreeting || "Good morning";
      break;
    case hourlyTimeNow < 17:
      greeting = afternoonGreeting || "Good afternoon";
      break;
    default:
      greeting = eveningGreeting || "Good evening";
      break;
  }

  return (
    <h2 className="m-0 body-xl" style={{ color: "#8c8c8c" }}>
      {greeting}, {name}!
    </h2>
  );
}
