import React from "react";
import Skeleton from "@mui/material/Skeleton";

export default function SkeletonSingleRecipeSuggestions() {
  return (
    <div className="flex flex-row w-full lg:w-1/2 mt-m">
      <div
        className="relative p-0 w-44 sm:w-64 max-w-full shrink-0 pr-1 lg:pr-0"
        style={{ maxWidth: "46.875vw" }}
      >
        <div className="relative aspect-[1/1] sm:aspect-[16/10]">
          <Skeleton
            variant="rectangular"
            animation="wave"
            className="rounded-xl object-cover bg-lighter-grey min-h-full min-w-full"
          />
        </div>
      </div>

      <div className="flex flex-col justify-start ml-m mr-xs my-m w-80">
        <h4 className="m-0">
          <Skeleton variant="text" animation="wave" />
        </h4>
        <p className="m-0 mt-1">
          <Skeleton variant="text" animation="wave" width="40%" />
        </p>
      </div>
    </div>
  );
}
