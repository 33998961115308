import React from "react";

type Article = {
  id: string;
  url: string;
  title: string;
  label: string;
  image: {
    id: string;
    filename_disk: string;
  };
};

export default function FeaturedArticles({
  title,
  articles,
  buttonLabel,
  buttonLink,
}: {
  title: string;
  articles: Array<Article>;
  buttonLabel?: string;
  buttonLink?: string;
}) {
  return (
    <div className="mt-l">
      <div className="flex flex-row justify-between items-center">
        <h2 className="display-xs m-0">{title}</h2>
        {buttonLink && buttonLabel && (
          <a href={buttonLink} className="label-m hover:underline" style={{ color: "#6187CD" }}>
            {buttonLabel}
          </a>
        )}
      </div>

      <ul className="list-none flex lg:flex-row flex-wrap m-0 p-0">
        {articles?.map((article) => (
          <ArticlePreview key={article.url + Date.now()} article={article}></ArticlePreview>
        ))}
      </ul>
    </div>
  );
}

function ArticlePreview({ article }: { article: Article }) {
  return (
    <li className="w-full lg:w-1/2 shrink-0 m-0 mt-m mb-0">
      <a key={article.url + Date.now()} href={article.url} className="flex flex-col sm:flex-row">
        {article.image && (
          <img
            src={`${(process.env.GATSBY_DIRECTUS_API_URL || "https://cms.prod.dietdoctor.com")?.replace(
              "/graphql",
              ""
            )}/assets/${article.image?.filename_disk}?fit=cover&width=640&height=360&quality=84&format=webp`}
            className="relative p-0 min-h-[144px] h-full w-64 shrink-0 rounded-xl overflowfix bg-lighter-grey"
            alt={article.title}
          />
        )}

        <div className="flex flex-col mt-xs sm:ml-m mr-m">
          <span className="label-s font-medium" style={{ color: "#9F9F9F", letterSpacing: "1px" }}>
            {article.label}
          </span>
          <h3 className="label-xl m-0 text-black">{article.title}</h3>
        </div>
      </a>
    </li>
  );
}
