import React from "react";
import { useDDProTranslations } from "../../../dd-pro/useDDProTranslations";

export function BretBlock() {
  const tt = useDDProTranslations();
  return (
    <div className="mt-2 rounded-md bg-lightest-grey p-6 col-span-2 sm:flex sm:flex-col md:grid md:grid-cols-4">
      <div className="col-span-1">
        <img
          className="rounded-full w-32 h-32 my-2"
          src="https://i.dietdoctor.com/wp-content/uploads/2018/09/bret-scher-2.jpg?auto=compress%2Cformat&w=300&h=300&fit=crop"
        />
      </div>
      <div className="col-span-3">
        <p>
          {tt.weightLossAndDietTipsAreEverywhereHelpfulContentThatsBothAccurateAndEmpoweringIsNotWelcomeToDdPro}
        </p>
        <p className="mb-0 pb-0 font-medium">Dr. Bret Scher, MD</p>
        <p className="mt-0 pt-0">Medical Director, Diet Doctor</p>
      </div>
    </div>
  );
}
